import React from "react";
  const Hamburger = () => (
    <svg height="32px" viewBox="0 0 32 32" width="32px" xmlns="http://www.w3.org/2000/svg">
      <path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z" />
    </svg>
  );

const ArrowLeft = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M5.85 15.27C5.11 15.02 4.42 14.66 3.8 14.21C3.18 13.75 2.64 13.22 2.19 12.6C1.73 11.98 1.38 11.3 1.13 10.55C0.87 9.8 0.75 9.02 0.75 8.2C0.75 7.38 0.87 6.59 1.13 5.85C1.37 5.1 1.73 4.42 2.18 3.8C2.63 3.18 3.18 2.64 3.8 2.18C4.42 1.72 5.1 1.37 5.85 1.12C6.59 0.88 7.37 0.75 8.2 0.75C9.02 0.75 9.81 0.87 10.55 1.13C11.29 1.38 11.98 1.73 12.6 2.19C13.22 2.65 13.76 3.18 14.21 3.8C14.66 4.42 15.02 5.1 15.27 5.85C15.52 6.6 15.65 7.38 15.65 8.2C15.65 9.02 15.52 9.8 15.27 10.55C15.02 11.29 14.67 11.98 14.21 12.6C13.75 13.22 13.22 13.76 12.6 14.21C11.98 14.67 11.3 15.02 10.55 15.27C9.81 15.52 9.02 15.65 8.2 15.65C7.37 15.65 6.59 15.52 5.85 15.27ZM10.79 15.98C11.61 15.71 12.36 15.32 13.05 14.82C13.73 14.32 14.32 13.73 14.83 13.04C15.33 12.36 15.72 11.61 15.99 10.79C16.27 9.97 16.4 9.11 16.4 8.2C16.4 7.29 16.26 6.43 15.99 5.61C15.72 4.79 15.33 4.04 14.83 3.36C14.33 2.68 13.74 2.08 13.05 1.58C12.37 1.08 11.61 0.69 10.79 0.41C9.97 0.14 9.1 0 8.2 0C7.29 0 6.43 0.14 5.61 0.41C4.79 0.69 4.04 1.08 3.36 1.58C2.68 2.08 2.08 2.67 1.58 3.36C1.08 4.04 0.69 4.79 0.41 5.61C0.14 6.43 0 7.29 0 8.2C0 9.11 0.14 9.97 0.41 10.79C0.69 11.61 1.07 12.36 1.58 13.04C2.08 13.72 2.67 14.32 3.36 14.82C4.04 15.32 4.79 15.71 5.61 15.98C6.43 16.26 7.29 16.39 8.2 16.39C9.1 16.4 9.97 16.26 10.79 15.98ZM8.14 11.51L5.17 8.56H12.58V7.84H5.17L8.14 4.87L7.64 4.38L3.82 8.2L7.64 12.02L8.14 11.51Z" />
    </g>
  </svg>
);

const ArrowRight = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M5.85 15.27C5.11 15.02 4.42 14.66 3.8 14.21C3.18 13.75 2.64 13.21 2.18 12.59C1.72 11.97 1.37 11.29 1.12 10.54C0.87 9.8 0.75 9.02 0.75 8.2C0.75 7.38 0.87 6.59 1.13 5.85C1.37 5.1 1.73 4.42 2.18 3.8C2.64 3.18 3.18 2.64 3.8 2.18C4.42 1.72 5.1 1.37 5.85 1.12C6.59 0.88 7.37 0.75 8.2 0.75C9.03 0.75 9.81 0.87 10.55 1.13C11.29 1.38 11.98 1.73 12.6 2.19C13.22 2.65 13.76 3.18 14.21 3.8C14.66 4.42 15.02 5.1 15.27 5.85C15.52 6.59 15.65 7.38 15.65 8.2C15.65 9.02 15.53 9.8 15.27 10.55C15.02 11.29 14.67 11.98 14.21 12.6C13.75 13.22 13.22 13.76 12.6 14.22C11.98 14.68 11.3 15.03 10.55 15.28C9.81 15.53 9.02 15.66 8.2 15.66C7.38 15.66 6.59 15.52 5.85 15.27ZM10.79 15.99C11.61 15.71 12.36 15.33 13.04 14.82C13.72 14.32 14.31 13.73 14.82 13.04C15.32 12.36 15.71 11.6 15.98 10.79C16.26 9.97 16.39 9.11 16.39 8.2C16.39 7.29 16.25 6.43 15.98 5.61C15.7 4.79 15.32 4.04 14.82 3.36C14.32 2.68 13.73 2.08 13.04 1.58C12.36 1.08 11.6 0.69 10.79 0.41C9.97 0.14 9.1 0 8.2 0C7.29 0 6.43 0.14 5.61 0.41C4.79 0.69 4.04 1.08 3.36 1.58C2.68 2.08 2.08 2.67 1.58 3.36C1.08 4.04 0.69 4.79 0.41 5.61C0.14 6.43 0 7.29 0 8.2C0 9.11 0.14 9.97 0.41 10.79C0.69 11.61 1.07 12.36 1.58 13.04C2.08 13.72 2.67 14.32 3.36 14.82C4.04 15.32 4.79 15.71 5.61 15.99C6.43 16.26 7.29 16.4 8.2 16.4C9.1 16.4 9.97 16.26 10.79 15.99ZM12.59 8.2L8.77 4.38L8.27 4.88L11.24 7.84H3.82V8.56H11.23L8.26 11.52L8.76 12.02L12.59 8.2Z" />
    </g>
  </svg>
);

const CloseIcon = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M5.85 15.27C5.11 15.02 4.42 14.66 3.8 14.21C3.18 13.75 2.64 13.22 2.19 12.6C1.73 11.98 1.38 11.3 1.13 10.55C0.87 9.8 0.75 9.02 0.75 8.2C0.75 7.38 0.87 6.59 1.13 5.85C1.37 5.1 1.73 4.42 2.18 3.8C2.64 3.18 3.18 2.64 3.8 2.18C4.42 1.72 5.1 1.37 5.85 1.12C6.6 0.87 7.37 0.75 8.2 0.75C9.02 0.75 9.81 0.87 10.55 1.13C11.29 1.38 11.98 1.73 12.6 2.19C13.22 2.65 13.76 3.18 14.21 3.8C14.67 4.42 15.02 5.1 15.27 5.85C15.52 6.59 15.65 7.38 15.65 8.2C15.65 9.02 15.53 9.8 15.27 10.55C15.02 11.29 14.67 11.98 14.21 12.6C13.75 13.22 13.22 13.76 12.6 14.21C11.98 14.67 11.3 15.02 10.55 15.27C9.81 15.52 9.02 15.65 8.2 15.65C7.37 15.65 6.59 15.52 5.85 15.27ZM10.79 15.98C11.61 15.7 12.36 15.32 13.05 14.81C13.73 14.31 14.32 13.72 14.83 13.03C15.33 12.35 15.72 11.6 16 10.78C16.26 9.97 16.4 9.1 16.4 8.2C16.4 7.29 16.26 6.43 15.99 5.61C15.71 4.79 15.33 4.04 14.82 3.36C14.32 2.68 13.73 2.09 13.04 1.58C12.36 1.08 11.6 0.69 10.78 0.42C9.97 0.14 9.1 0 8.2 0C7.29 0 6.43 0.14 5.61 0.41C4.79 0.69 4.04 1.08 3.35 1.58C2.67 2.08 2.07 2.67 1.57 3.36C1.07 4.04 0.68 4.8 0.41 5.61C0.14 6.43 0 7.29 0 8.2C0 9.11 0.14 9.97 0.41 10.79C0.68 11.61 1.07 12.36 1.57 13.04C2.07 13.72 2.66 14.32 3.35 14.82C4.03 15.32 4.78 15.71 5.6 15.99C6.42 16.26 7.28 16.4 8.19 16.4C9.1 16.4 9.97 16.26 10.79 15.98ZM11.03 10.51L5.88 5.38L5.37 5.88L10.53 11.02L11.03 10.51ZM11.03 5.88L10.52 5.38L5.37 10.51L5.88 11.02L11.03 5.88Z" />
    </g>

  </svg>
);

const Dropdown = (props: any) => (
  <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path d="M4.22 4.92L0 0.71L0.71 0L4.22 3.51L7.72 0L8.43 0.71L4.22 4.92Z" />
    </g>
  </svg>
);

const Dropup = (props: any) => (
  <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path d="M4.22 0L8.43 4.22L7.72 4.92L4.22 1.41L0.71 4.92L0 4.22L4.22 0Z" />
    </g>
  </svg>
);

const ArrowDown = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M5.85 15.27C5.1 15.02 4.42 14.66 3.8 14.21C3.18 13.75 2.64 13.22 2.18 12.6C1.72 11.98 1.37 11.3 1.12 10.55C0.87 9.8 0.75 9.02 0.75 8.2C0.75 7.38 0.87 6.59 1.13 5.85C1.37 5.1 1.73 4.42 2.18 3.8C2.64 3.18 3.18 2.64 3.8 2.18C4.42 1.72 5.1 1.37 5.85 1.12C6.59 0.88 7.37 0.75 8.2 0.75C9.02 0.75 9.81 0.87 10.55 1.13C11.29 1.39 11.98 1.73 12.6 2.19C13.22 2.65 13.76 3.18 14.21 3.81C14.67 4.43 15.02 5.11 15.27 5.86C15.52 6.6 15.65 7.39 15.65 8.21C15.65 9.03 15.53 9.81 15.27 10.56C15.02 11.3 14.67 11.99 14.21 12.61C13.75 13.23 13.22 13.77 12.6 14.22C11.98 14.68 11.3 15.03 10.55 15.28C9.81 15.53 9.02 15.66 8.2 15.66C7.37 15.65 6.59 15.52 5.85 15.27ZM10.79 15.98C11.61 15.7 12.36 15.32 13.04 14.82C13.72 14.32 14.32 13.73 14.82 13.04C15.32 12.36 15.71 11.61 15.98 10.79C16.26 9.97 16.39 9.11 16.39 8.2C16.39 7.29 16.25 6.43 15.98 5.61C15.71 4.79 15.32 4.04 14.82 3.36C14.32 2.68 13.73 2.08 13.04 1.58C12.36 1.08 11.6 0.69 10.79 0.41C9.97 0.14 9.1 0 8.2 0C7.3 0 6.43 0.14 5.61 0.41C4.79 0.69 4.04 1.08 3.36 1.58C2.68 2.08 2.08 2.67 1.58 3.36C1.08 4.04 0.69 4.79 0.41 5.61C0.14 6.43 0 7.29 0 8.2C0 9.11 0.14 9.97 0.41 10.79C0.69 11.61 1.07 12.36 1.58 13.04C2.08 13.72 2.67 14.32 3.36 14.82C4.04 15.32 4.79 15.71 5.61 15.98C6.43 16.26 7.29 16.39 8.2 16.39C9.11 16.39 9.97 16.26 10.79 15.98ZM7.84 3.84V11.26L4.87 8.29L4.38 8.8L8.2 12.62L12.03 8.8L11.52 8.3L8.56 11.27V3.84H7.84Z" />
    </g>
  </svg>
);

const ArrowUp = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M5.85 15.27C5.11 15.02 4.42 14.67 3.8 14.21C3.18 13.75 2.64 13.22 2.18 12.6C1.72 11.98 1.37 11.3 1.12 10.55C0.87 9.8 0.75 9.02 0.75 8.2C0.75 7.38 0.87 6.59 1.13 5.85C1.37 5.1 1.73 4.42 2.18 3.8C2.64 3.18 3.18 2.64 3.8 2.18C4.42 1.72 5.1 1.37 5.85 1.12C6.59 0.88 7.37 0.75 8.2 0.75C9.02 0.75 9.81 0.88 10.55 1.13C11.29 1.38 11.98 1.73 12.6 2.19C13.22 2.65 13.76 3.18 14.21 3.81C14.67 4.43 15.02 5.11 15.27 5.86C15.52 6.6 15.65 7.39 15.65 8.21C15.65 9.03 15.53 9.81 15.27 10.56C15.02 11.31 14.67 11.99 14.21 12.61C13.75 13.23 13.22 13.77 12.6 14.22C11.98 14.68 11.3 15.03 10.55 15.28C9.81 15.53 9.02 15.66 8.2 15.66C7.37 15.65 6.59 15.52 5.85 15.27ZM10.79 15.98C11.61 15.7 12.36 15.32 13.05 14.81C13.73 14.31 14.32 13.72 14.83 13.03C15.33 12.35 15.72 11.6 15.99 10.78C16.27 9.96 16.4 9.1 16.4 8.19C16.4 7.28 16.26 6.42 15.99 5.6C15.71 4.78 15.33 4.03 14.83 3.35C14.33 2.67 13.74 2.07 13.05 1.57C12.37 1.07 11.61 0.68 10.79 0.41C9.97 0.14 9.1 0 8.2 0C7.29 0 6.43 0.14 5.61 0.41C4.79 0.69 4.04 1.08 3.35 1.58C2.67 2.08 2.07 2.67 1.57 3.36C1.07 4.04 0.68 4.79 0.4 5.61C0.14 6.43 0 7.29 0 8.2C0 9.11 0.14 9.97 0.41 10.79C0.69 11.61 1.07 12.36 1.58 13.04C2.08 13.72 2.67 14.32 3.36 14.82C4.04 15.32 4.79 15.71 5.62 15.99C6.44 16.26 7.3 16.4 8.21 16.4C9.1 16.4 9.97 16.26 10.79 15.98ZM8.56 12.56V5.14L11.53 8.11L12.02 7.61L8.21 3.78L4.38 7.6L4.89 8.1L7.85 5.13V12.55H8.56V12.56Z" />
    </g>
  </svg>
);


export { ArrowLeft, ArrowRight, ArrowUp, ArrowDown, CloseIcon, Dropdown, Dropup, Hamburger };

import React, { FC, useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { Routes } from "../../constants/routes";
import { Hamburger, CloseIcon } from "../Icons";
import { AnimatePresence, motion } from "framer-motion";

const Container = styled("div")<{ isDark: boolean }>`
  color: ${props => (props.isDark ? "black" : "#fcfbf7")};
  height: 6rem;
  padding: 0 var(--s4);
  display: flex;
  justify-content: space-between;
  align-items: center;
  h1 {
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${props => (props.isDark ? "black" : "#fcfbf7")};
  }

  a,
  a:visited,
  a:link {
    font-weight: 300;
    text-decoration: none;
    color: black;
    display: block;
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 300;
    transition: all 0.3s;
    color: ${props => (props.isDark ? "black" : "#fcfbf7")};

    &:hover {
      text-decoration: underline;
      color: black;
    }
    margin-top: var(--s4);
  }

  .menuTitle {
    font-size: 1.6rem;
  }

  nav {
    background: #e3e1de;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    padding: 0 var(--s4);
    overflow-y: hidden;
    a {
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: 300;
    }
    h1 {
      font-size: 2.5rem;
    }

    ul {
      margin-top: 3rem;
    }

    li {
      margin-top: 3rem;
    }

    button {
      background: none;
      border: none;
      display: block;
      margin-top: 1.24rem;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      /* padding-left: var(--s8); */
      a {
        margin-left: 0 !important;
      }
      svg {
        fill: black;
        display: block;
        width: var(--s12);
        height: var(--s12);
      }
    }
  }
  @media screen and (min-width: 700px) {
    display: none;
  }
`;

const IconButton = styled("button")<{ isDark: boolean }>`
  background: none;
  border: none;
  cursor: pointer;
  fill: ${props => (props.isDark ? "black" : "#fcfbf7")};
`;

const MobileNavbar: FC<{ activePage: Routes }> = ({ activePage }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  function handleClick(e: any) {
    // e.preventDefault();
    setIsNavOpen(false);
  }
  return (
    <Container isDark={activePage !== Routes.HOME || isNavOpen}>
      <Link onClick={handleClick} style={{ textDecoration: activePage === Routes.HOME ? "underline" : "" }} to={Routes.HOME}>
        <h1>Bruno Heller</h1>
      </Link>
      <IconButton onClick={() => setIsNavOpen(true)} isDark={activePage !== Routes.HOME}>
        <Hamburger />
      </IconButton>
      <AnimatePresence>
        {isNavOpen && (
          <motion.nav initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <button onClick={() => setIsNavOpen(false)}>
              <Link
                onClick={handleClick}
                style={{ display: "block", textDecoration: activePage === Routes.HOME ? "underline" : "" }}
                to={Routes.HOME}
              >
                <h1 className="menuTitle">Bruno Heller</h1>
              </Link>
              <CloseIcon />
            </button>
            <ul>
              <li>
                <Link
                  onClick={handleClick}
                  style={{ textDecoration: activePage === Routes.WERKVERZEICHNIS ? "underline" : "" }}
                  to={Routes.WERKVERZEICHNIS}
                >
                  Werkverzeichnis
                </Link>
              </li>
              <li>
                <Link
                  onClick={handleClick}
                  style={{
                    textDecoration: activePage === Routes.BIOGRAFIE ? "underline" : "",
                  }}
                  to={Routes.BIOGRAFIE}
                >
                  Biografie
                </Link>
              </li>
              <li>
                <Link
                  onClick={handleClick}
                  style={{
                    textDecoration: activePage === Routes.AUSTELLUNGEN ? "underline" : "",
                  }}
                  to={Routes.AUSTELLUNGEN}
                >
                  Ausstellungen
                </Link>
              </li>
              <li>
                <Link
                  onClick={handleClick}
                  style={{
                    textDecoration: activePage === Routes.KUNSTSAMMLUNGEN ? "underline" : "",
                  }}
                  to={Routes.KUNSTSAMMLUNGEN}
                >
                  Kunstsammlungen
                </Link>
              </li>
              <li>
                <Link
                  onClick={handleClick}
                  style={{
                    textDecoration: activePage === Routes.FOTOS ? "underline" : "",
                  }}
                  to={Routes.FOTOS}
                >
                  Fotos
                </Link>
              </li>
              <li>
                <Link
                  onClick={handleClick}
                  style={{ textDecoration: activePage === Routes.PUBLIKATIONEN ? "underline" : "" }}
                  to={Routes.PUBLIKATIONEN}
                >
                  Publikationen
                </Link>
              </li>
              <li>
                <Link
                  onClick={handleClick}
                  style={{ textDecoration: activePage === Routes.KONTAKT ? "underline" : "" }}
                  to={Routes.KONTAKT}
                >
                  Kontakt
                </Link>
              </li>
            </ul>
          </motion.nav>
        )}
      </AnimatePresence>
    </Container>
  );
};

export default MobileNavbar;

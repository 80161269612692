import React, { FC, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled, { createGlobalStyle } from "styled-components";
import useWerke from "../hooks/useWerke";
import DesktopNavbar from "./desktop/DesktopNavbar";
import { Routes } from "../constants/routes";
import { motion } from "framer-motion";
import MobileNavbar from "./mobile/MobileNavbar";
import "react-image-lightbox/style.css";

const GlobalStyle = createGlobalStyle`
*, *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  font-weight: normal;
  line-height: 1.6;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  font-family: 'Inter', 'sans-serif';
  font-weight: 300;
}

ol, ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
}
a:visited{
  color: currentColor;
}
.ril-toolbar {
  background: none;
}

:root {
  --s1: 0.25rem;
  --s2: 0.5rem;
  --s3: 0.75rem;
  --s4: 1rem;
  --s5: 1.25rem;
  --s6: 1.5rem;
  --s8: 2rem;
  --s10: 2.5rem;
  --s12: 3rem;
  --s16: 4rem;
  --s20: 5rem;
  --s24: 6rem;
  --s32: 8rem;
  --s40: 10rem;
  --s48: 12rem;
  --s56: 14rem;
  --s64: 16rem;
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  --shadow-2xl: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  --light-grey: #e3e1de;
  --light-gray: var(--light-grey);
  --grey: #91908c;
  --gray: var(--grey);
  --black: #474643;
  --off-white: #fcfbf7;
  --lighter-gray: #a5a4a1;
  --lighter-grey: var(--lighter-gray);
}

body {
  background: var(--light-grey);
  font-weight: 300;
  overflow-y: ${(props: any) => (props.activePage === Routes.WERKVERZEICHNIS ? "hidden" : "auto")};
}
`;

const Container = styled.div`
  background: var(---light-grey);
  margin: 0 auto;
  width: 100%;
  max-width: 1800px;

`;

const Layout: FC<{ activePage: Routes }> = ({ children, activePage }) => {
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      const links = document.getElementsByTagName("a");
      for (let i = 0, len = links.length; i < len; i++) {
        
        links[i].target = "_blank";
      }
    }
  }, []);
  return (
    <Container>
      <GlobalStyle activePage={activePage} />
      <header>
        <DesktopNavbar activePage={activePage} />
        <MobileNavbar activePage={activePage} />
      </header>
      <main>{children}</main>
    </Container>
  );
};

export default Layout;

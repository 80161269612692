import React, { FC, useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { Routes } from "../../constants/routes";
import { Dropdown, Dropup } from "../Icons";
import { AnimatePresence, motion } from "framer-motion";

const Container = styled("div")<{ isDark: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${props => (props.isDark ? "black" : "#fcfbf7")};
  height: 6rem;
  padding: 0 var(--s12);
  h1 {
    font-size: 2rem;
    line-height: 3.3rem;
  }
  nav {
    ul {
      display: grid;
      grid-gap: var(--s8);
      grid-auto-flow: column;
    }
  }

  a,
  a:visited,
  a:link {
    font-weight: 300;
    text-decoration: none;
    color: ${props => (props.isDark ? "black" : "#fcfbf7")};
    font-size: 2rem;
    line-height: 3.3rem;
    transition: all 0.3s;
    &:hover {
      text-decoration: underline;
    }
  }
  .bildermacher {
    position: relative;
    font-weight: 300;
    cursor: pointer;
    font-size: 2rem;
    line-height: 3.3rem;
    svg {
      fill: ${props => (props.isDark ? "black" : "#fcfbf7")};
      height: var(--s6);
      width: var(--s6);
      margin-bottom: -0.3rem;
    }
  }

  .submenu {
    position: absolute;
    top: 4rem;
    left: -1rem;
    z-index: 2;
    padding: var(--s4) var(--s4);
    background: var(--light-grey);
    display: grid;
    grid-auto-flow: row;
    a {
      color: black;
    }
  }

  @media screen and (max-width: 700px) {
    display: none;
  }
`;

const DesktopNavbar: FC<{ activePage: Routes }> = ({ activePage }) => {
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  return (
    <Container isDark={activePage !== Routes.HOME}>
      <Link style={{ textDecoration: activePage === Routes.HOME ? "underline" : "" }} to={Routes.HOME}>
        <h1>Bruno Heller</h1>
      </Link>
      <nav>
        <ul>
          <li>
            <Link
              style={{ textDecoration: activePage === Routes.WERKVERZEICHNIS ? "underline" : "" }}
              to={Routes.WERKVERZEICHNIS}
            >
              Werkverzeichnis
            </Link>
          </li>
          <li>
            <div className="bildermacher" onClick={() => setIsSubmenuOpen(!isSubmenuOpen)}>
              Bildermacher {isSubmenuOpen ? <Dropup /> : <Dropdown />}
              <AnimatePresence>
                {isSubmenuOpen && (
                  <motion.ul
                    className="submenu"
                    transition={{ duration: 0 }}
                    initial={{ opacity: 1 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 1 }}
                  >
                    <li>
                      <Link
                        style={{
                          padding: "1rem 0",
                          textDecoration: activePage === Routes.BIOGRAFIE ? "underline" : "",
                        }}
                        to={Routes.BIOGRAFIE}
                      >
                        Biografie
                      </Link>
                    </li>
                    <li>
                      <Link
                        style={{
                          padding: "1rem 0",
                          textDecoration: activePage === Routes.AUSTELLUNGEN ? "underline" : "",
                        }}
                        to={Routes.AUSTELLUNGEN}
                      >
                        Ausstellungen
                      </Link>
                    </li>
                    <li>
                      <Link
                        style={{
                          padding: "1rem 0",
                          textDecoration: activePage === Routes.KUNSTSAMMLUNGEN ? "underline" : "",
                        }}
                        to={Routes.KUNSTSAMMLUNGEN}
                      >
                        Kunstsammlungen
                      </Link>
                    </li>
                    <li>
                      <Link
                        style={{
                          padding: "1rem 0",
                          textDecoration: activePage === Routes.FOTOS ? "underline" : "",
                        }}
                        to={Routes.FOTOS}
                      >
                        Fotos
                      </Link>
                    </li>
                  </motion.ul>
                )}
              </AnimatePresence>
            </div>
          </li>
          <li>
            <Link
              style={{ textDecoration: activePage === Routes.PUBLIKATIONEN ? "underline" : "" }}
              to={Routes.PUBLIKATIONEN}
            >
              Publikationen
            </Link>
          </li>
          <li>
            <Link style={{ textDecoration: activePage === Routes.KONTAKT ? "underline" : "" }} to={Routes.KONTAKT}>
              Kontakt
            </Link>
          </li>
        </ul>
      </nav>
    </Container>
  );
};

export default DesktopNavbar;

export enum Routes {
  HOME = "/",
  WERKVERZEICHNIS = "/werkverzeichnis",
  PUBLIKATIONEN = "/publikationen",
  KONTAKT = "/kontakt",
  WERK = "/werk",
  FOTOS = "/fotos",
  AUSTELLUNGEN = "/ausstellungen",
  BIOGRAFIE = "/biografie",
  KUNSTSAMMLUNGEN = "/kunstsammlungen",
}
